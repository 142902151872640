.photoSlider {
  width: 100%;
  margin: 0 auto;
  text-align: center; }
  .photoSlider img {
    width: calc(33% - 30px);
    margin: 15px;
    float: left;
    border: 1px solid #CFD8DC;
    cursor: pointer; }
  .photoSlider button {
    padding: 10px 20px;
    text-transform: uppercase;
    cursor: pointer;
    background-color: transparent;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    color: #fad7a0;
    border: 1px solid #fad7a0;
    margin-top: 15px;
    font-size: 18px;
    float: right;
    margin-right: 30px; }
    .photoSlider button:hover {
      color: black;
      background-color: #fad7a0; }
    .photoSlider button i {
      margin-left: 10px; }

@media (max-width: 500px) {
  .photoSlider {
    margin-right: 0;
    width: 100%;
    padding: 10px; }
    .photoSlider img {
      width: calc(50% - 10px);
      margin: 5px; }
    .photoSlider button {
      margin-right: 5px; } }

@media (min-width: 1701px) {
  .photoSlider {
    width: 80%; } }
