.bellyMenu {
  width: 100%;
  position: absolute;
  top: 0;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: end;
  padding: 20px;
  font-size: 20px;
  text-transform: uppercase; }
  .bellyMenu .item {
    color: #78909C;
    margin: 0 20px;
    cursor: pointer;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out; }
    .bellyMenu .item:hover {
      color: #f4f6f6; }
  .bellyMenu .selected {
    color: #f7dc6f; }

@media (max-width: 500px) {
  .bellyMenu {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap; }
    .bellyMenu .item {
      width: calc(50% - 40px);
      text-align: center;
      margin-bottom: 10px; } }
