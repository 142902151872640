.sectionTitle {
  width: 100%;
  position: relative;
  margin-bottom: 10px; }
  .sectionTitle .border {
    width: 100%;
    height: 1px;
    background-color: #566573;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    z-index: 5; }
  .sectionTitle .title {
    position: relative;
    font-size: 40px;
    text-align: center;
    color: white;
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    background-color: #17202a;
    padding: 0 20px;
    z-index: 10;
    text-transform: uppercase;
    font-family: 'Poiret One', cursive; }
