.indexPage {
  width: 100%;
  min-height: 100vh;
  background-color: #17202a;
  position: relative; }
  .indexPage .vase {
    float: left;
    margin-left: -150px; }
  .indexPage .workSpace {
    width: 75%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px; }
  .indexPage .icon {
    font-size: 70px;
    color: #154360; }
  .indexPage .title {
    margin-top: 25px;
    font-size: 22px;
    color: #2c3e50; }
  .indexPage .subtitle {
    margin-top: 5px;
    font-size: 14px;
    color: #566573;
    padding: 0 40px; }
  .indexPage .phone {
    margin-top: 20px; }
  .indexPage .address {
    margin-top: 5px; }
  .indexPage .left {
    width: 10%;
    height: 100%;
    float: left;
    font-size: 30px;
    margin-top: 30%;
    color: #154360;
    cursor: pointer; }
  .indexPage .modalImage {
    width: 80%;
    margin-top: 10px;
    float: left; }
  .indexPage .right {
    width: 10%;
    font-size: 30px;
    float: left;
    margin-top: 30%;
    color: #154360;
    cursor: pointer; }

@media (max-width: 500px) {
  .indexPage {
    min-height: 250vh; }
    .indexPage .vase {
      display: none; }
    .indexPage .workSpace {
      width: 100%; } }

@media (min-width: 501px) and (max-width: 800px) {
  .indexPage .vase {
    margin-left: -500px; }
  .indexPage .workSpace {
    width: 85%; } }

@media (min-width: 801px) and (max-width: 1050px) {
  .indexPage .vase {
    margin-left: -400px; }
  .indexPage .workSpace {
    width: 80%; } }

@media (min-width: 1051px) and (max-width: 1700px) {
  .indexPage .vase {
    margin-left: -400px; }
  .indexPage .workSpace {
    width: 85%; } }
