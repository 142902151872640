.footer {
  width: 100%;
  text-align: center;
  padding: 30px; }
  .footer .title {
    font-size: 30px;
    color: #ECEFF1; }
  .footer .phone {
    font-size: 22px;
    color: #B0BEC5; }
  .footer .address {
    font-size: 18px;
    color: #90A4AE; }
  .footer .copy {
    font-size: 14px;
    color: #546E7A; }
