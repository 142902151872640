.modal {
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out;
  overflow: hidden; }
  .modal .background {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(23, 32, 42, 0.5);
    z-index: 10;
    overflow: hidden;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out; }
  .modal .body {
    overflow: hidden;
    max-width: 800px;
    background-color: white;
    z-index: 100;
    position: absolute;
    text-align: center;
    -webkit-transition: all .2s ease-in-out;
    -o-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    margin: -500vh auto 0 auto;
    width: 98%;
    left: 0;
    right: 0;
    padding: 20px 20px 30px 20px;
    border-radius: 6px; }
    .modal .body .close {
      width: 100%;
      font-size: 16px;
      color: #abb2b9;
      text-align: right;
      cursor: pointer; }

.open {
  -webkit-transition: all .2s ease-in-out;
  -o-transition: all .2s ease-in-out;
  transition: all .2s ease-in-out; }
  .open .background {
    width: 100%;
    right: 0;
    bottom: 0; }
  .open .body {
    margin: 0 auto; }
