.header {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  color: white;
  text-align: center; }
  .header .bold {
    font-weight: bold; }
  .header .belly {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: -10px; }
    .header .belly .spacer {
      width: 67%; }
      .header .belly .spacer .bar {
        margin-top: 20px;
        height: 2px;
        background-color: white; }
    .header .belly .list {
      width: 33%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      font-size: 30px;
      text-transform: uppercase;
      text-align: left;
      padding: 10px;
      font-weight: 300;
      color: #85c1e9; }
      .header .belly .list .first {
        color: #f7dc6f; }
      .header .belly .list .last {
        color: #f1948a; }
  .header .logo {
    font-size: 50px;
    font-family: 'Poiret One', cursive; }
  .header .description {
    font-size: 17px;
    width: 100%;
    margin: 15px auto;
    color: #B0BEC5; }
  .header .holder {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    margin-top: 10px; }
    .header .holder button {
      width: 48%;
      padding: 10px;
      text-transform: uppercase;
      cursor: pointer;
      background-color: transparent;
      -webkit-transition: all .2s ease-in-out;
      -o-transition: all .2s ease-in-out;
      transition: all .2s ease-in-out; }
    .header .holder .services {
      color: #b2babb;
      border: 2px solid #b2babb;
      font-size: 18px; }
      .header .holder .services:hover {
        color: black;
        background-color: #b2babb; }
    .header .holder .contact {
      color: #82e0aa;
      border: 2px solid #82e0aa;
      font-size: 18px; }
      .header .holder .contact:hover {
        color: black;
        background-color: #82e0aa; }

@media (max-width: 500px) {
  .header .holder {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column; }
    .header .holder button {
      width: 100%;
      margin-bottom: 10px; }
  .header .belly .list {
    font-size: 25px; }
  .header .logo {
    font-size: 40px; } }
